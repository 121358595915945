// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  hmr: true,
  keys: {
    token: 'TOKEN_PROXY_KEY',
    refreshToken: 'REFRESH_TOKEN_PROXY_KEY',
    user: 'USER_PROXY_KEY',
  },
  config: {
    redirectToWhenAuthenticated: '/pages/admins',
    redirectToWhenUnauthenticated: '/auth/login',
  },
  api: {
    baseUrl: 'https://api.agro.ligafacens.com/',
    auth: {
      login: '/auth/local',
    },
    users: {
      list: '/users',
      create: '/users',
      getById: '/users/{id}',
      update: '/users/{id}',
      delete: '/users/{id}',
      me: '/users/me',
    },
    userPassword: {
      changePassword: '/users/password',
      forgotPasswordEmail: '/users/password/forgot/email/{email}',
      validateForgotPasswordCode: '/users/password/validate/{token}',
      forgotResetPassword: '/users/password/reset/{resetPasswordCode}',
    },
    articles: {
      list: '/articles',
      create: '/articles',
      getById: '/articles/{id}',
      update: '/articles/{id}',
      delete: '/articles/{id}',
    },
    categories: {
      list: '/categories',
      create: '/categories',
      getById: '/categories/{id}',
      update: '/categories/{id}',
      delete: '/categories/{id}',
    },
    cultures: {
      list: '/cultures',
      create: '/cultures',
      getById: '/cultures/{id}',
      update: '/cultures/{id}',
      delete: '/cultures/{id}',
    },
    cooperators: {
      list: '/cooperators',
      create: '/cooperators',
      getById: '/cooperators/{id}',
      update: '/cooperators/{id}',
      delete: '/cooperators/{id}',
    },
    landExpenses: {
      list: '/land-expenses',
      getById: '/land-expenses/{id}',
    },
    medias: {},
    plantingExpenses: {
      list: '/planting-expenses',
      getById: '/planting-expenses/{id}',
    },
    reports: {
      list: '/reports',
      create: '/reports',
      getById: '/reports/{id}',
      update: '/reports/{id}',
      delete: '/reports/{id}',
    },
  },
};
